<div id="querySelectDropdown" class="collapsible-menu--container">
  <div class="collapsible-menu--search-container">
    <div class="collapsible-menu--search-bar">
      <label for="query-title-filter"
             class="hidden-for-sighted"
             [textContent]="text.label" ></label>
      <input #wpQueryMenuSearchInput
             type="text"
             class="collapsible-menu--search-input ui-autocomplete--input ellipsis"
             name="query-title-filter"
             id="query-title-filter"
             [attr.placeholder]="text.search" />
      <i class="icon-search collapsible-menu--search-icon"></i>
    </div>
    <div #queryResultsContainer class="collapsible-menu--results-container">
      <p class="collapsible-menu--no-results-container"
         [hidden]="!noResults || loading"
         [textContent]="text.no_results"></p>
      <div class="loading-indicator -compact" *ngIf="loading">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
  </div>
</div>
