<form
  [formGroup]="projectAndTypeForm"
  (ngSubmit)="onSubmit($event)"
>
  <op-modal-header (close)="close.emit()">{{ text.title }}</op-modal-header>

  <div class="op-modal--body op-form">
    <op-form-field
      label="Project"
      required
    >
      <op-ium-project-search
        [opFormBinding]="projectControl"
        slot="input"
      ></op-ium-project-search>

      <div
        slot="help-text"
        *ngIf="projectControl!.errors?.lackingPermission"
      >
        {{ text.project.lackingPermissionInfo }}
      </div>

      <div
        slot="errors"
        class="op-form-field--error"
        *ngIf="projectControl!.errors?.required"
      >
        {{ text.project.required }}
      </div>

      <div
        slot="errors"
        class="op-form-field--error"
        *ngIf="projectControl!.errors?.lackingPermission"
      >
        {{ text.project.lackingPermission }}
      </div>
    </op-form-field>

    <op-form-field>
      <op-option-list
        [options]="typeOptions"
        formControlName="type"
        slot="input"
      ></op-option-list>

      <div
        *ngIf="projectAndTypeForm?.dirty && typeControl?.invalid"
        class="op-form-field--errors"
        slot="errors"
      >
        <div class="op-form-field--error">
          {{ text.type.required }}
        </div>
      </div>
    </op-form-field>
  </div>

  <div class="op-modal--footer">
    <button class="button -highlight">{{ text.nextButton }}</button>
  </div>
</form>
